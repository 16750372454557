/* our custom styles */

// DELETE ME:
.custom-theme-marker {
    display: none;
    position: absolute;
    top: 300px;
    left: 30%;
    margin: auto 0;
    transform: rotate(-20deg);
    background: #00ECFF;
    color: #fff;
    padding: 1em;
    z-index: 100;
}
.section-front-page .custom-theme-marker{
    display: block;
}


/* TinyMCE templates */

// image-grid-2x2:
.image-grid-2x2 {
    padding: 0;
    margin: 0 0 @grid-gutter-width 0;
    list-style: none;
    .make-row();

    > li {
        .make-xs-column(12);
        .make-sm-column(6);
        margin-top: @grid-gutter-width;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
}

/* CITTA Theme */

@media (min-width: 1200px) {
    .container {width: 1000px;}
}

#portal-header {
    margin: 0 auto;
    padding-top: 10px;
    margin-bottom: 10px;
}

body {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 1.42857143;
    color: #4d4d4d;
    background-color: #fafafa;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: justify;
    line-height: 1.8em;
}

h1.documentFirstHeading {
    padding-bottom: 9px;
    margin: 0px 0px 10px;
    border-bottom: 1px solid rgb(204, 204, 204);
    font-size: 26px;
    color: #8c2c18;
    text-align: left;
}

div#parent-fieldname-text > h3 {
/*    border-bottom: 1px solid rgb(204, 204, 204); */
    text-decoration: underline #cccccc;
    margin-bottom: 0.5em;
}

div#parent-fieldname-text > h4 {
/*    border-bottom: 1px solid rgb(204, 204, 204); */
text-decoration: underline #cccccc;
margin: 20px 0 0.5em 0;
color: #8c2c18;
font-style: italic;
}

h5 {
    padding: 20px 0 5px;
    text-decoration: underline #cccccc;
}

div.documentDescription.description {
    font-size: 12px;
    color: rgb(105, 105, 105);
    margin-bottom: 10px;
    font-style: italic;
}

.invisible-grid {
    width: 100%;
    margin-bottom: 20px;
    border: 0;
    background: transparent;
}

h4 > sub {
    bottom: .1em;
}

h2, .h2 {
    font-size: 20px;
}

.responsive-img {
    display: block;
    max-width: 100%;
    width: 100%;
    height: auto;
  }

  img {
    max-width: 100%;
  }

.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

aside#portal-footer-signature.portlet.portletClassic {
    display: none;
}

div.portletStaticText.portlet-static-fct {
    margin-top: -43px;
}

#portal-footer-wrapper p {
    border-bottom: none;
    padding-bottom: 0;
}

#portal-footer-wrapper {
    padding: 20px 15px;
    background-color: #2e3133;
    color: #cccccc;
    text-align: center;
    clear: both;
}

a {
    color: #8c2c18;
    text-decoration: none;
  }

/* Plone nav */

.nav > li > a:hover, .nav > li > a:focus {
    text-decoration: none;
    background-color: #a7daf2;
}

.plone-nav > li > a {
    position: relative;
    display: block;
    padding: 10px 10px;
    color: white;
    font-size: 13px;
}

.plone-navbar-nav li .submenu {
    width: 220px;
}

.plone-navbar-toggle {
    float: right;
    width: 55px;
    margin-right: 20px;
    padding-top: 5px;
}

.plone-navbar-toggle:hover {
    background-color: #8c2c18;
    float: right;
    width: 55px;
    margin-right: 20px;
}

.navbar {
    position: relative;
    min-height: 0px;
    margin-bottom: 5px;
    border: 1px solid transparent;
    background-color: #8c2c18;
}

.nav > li > a:hover, .nav > li > a:focus {
    text-decoration: none;
    background-color: #bdb8b8;
}

.plone-navbar-nav > .selected > a, .plone-navbar-nav > .selected > a:hover, .plone-navbar-nav > .selected > a:focus {
    color: white;
    background-color: #bdb8b8;
}

/* Login and Searchbox configuration */

#portal-anontools li {
    margin: 5px 0 12px 12px;
}

#portal-header #portal-searchbox .searchSection {
    display: none;
}

#portal-header #portal-searchbox form > div > * {
    font-size: 14px;
    height: 25px;
}

.principal {
    background: #fafafa;
    background-size: 20px 20px;
    /* margin-top: -20px; */
    /* margin-bottom: 20px; */
    /* padding: 30px 0; */
    padding: 15px 0;
}

div#hero.principal {
/*    height: 50px; */
    padding-top: 5px;
}

div.LSBox {
    margin-right: 5px;
    height: 30px;
}

div#portal-searchbox {
    float: right;
    margin-left: 0.5em;
    z-index: 2;
}

div#portal-anontools {
    width: 100px;
    float: right;
    margin-top: -3px;
}

div.searchSection {
    display: none;
}

input.searchButton {
    display: none;
}

input[type="text"], input[type="password"], textarea, select {
    display: block;
    width: 100%;
    height: 25px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #696969;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.portlet .portletHeader {
    background: #f2f1f1;
    font-weight: 400;
    font-size: 16px;
    padding: 5px 15px;
}

.portlet .portletContent {
    font-size: 12px;
    border-top: 1px solid #e5e5e5;
    background: #fff;
    font-weight: 400;
    text-align: left;
}

.portlet .portletContent > ul .portletItemDetails {
    display: block;
    z-index: 0;
    padding: 0 15px 10px 30px;
    font-weight: 100;
    position: relative;
    margin-top: -26px;
    font-size: 12px;
    color: #2b2828;
}

.portlet .portletFooter {
    border-top: 1px solid #e5e5e5;
    background: #fcfcfd;
    padding: 5px 15px;
    font-size: 12px;
    font-weight: 300;
    box-shadow: inset 0 10px 10px -10px rgba(0, 0, 0, 0.05);
}

/* webcouturier.dropdownmenu */

.plone-navbar-nav li {
    .caret {
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: 2px;
      vertical-align: middle;
      border-top:   (@plone-font-size-base * .25) dashed;
      border-right: (@plone-font-size-base * .25) solid transparent;
      border-left:  (@plone-font-size-base * .25) solid transparent;
    }
    &:hover > .submenu {
      display: block;
    }
    &.active:after,
    &:hover:after {
      content: "";
      display: block;
      position: absolute;
      bottom: -10px;
      width: 100%;
      z-index: 999;
    }
    .submenu {
      background-color: #8c2c18;
      display: none;
      list-style: none;
      padding-left: 0;
      padding-bottom: 0;
      position: absolute;
      z-index: 9999;
      .opener {
        background: transparent;
        float: right;
        text-align: right;
        width: @plone-font-size-base * 2;
        height: @plone-font-size-base * 2;
        margin-top: -(@plone-font-size-base * 0.4);
        margin-right: -(@plone-font-size-base * 0.7);
        .caret {
          display: inline-block;
          width: 0;
          height: 0;
          margin-left: 2px;
          vertical-align: bottom;
          border-left:   (@plone-font-size-base * .25) dashed;
          border-bottom: (@plone-font-size-base * .25) solid transparent;
          border-top:  (@plone-font-size-base * .25) solid transparent;
        }
      }
      a {
        color: white;
        display: block;
        line-height: 20px;
        min-width: 14em;
        padding: 10px 15px 10px 15px;
        position: relative;
        &:hover {
          background-color: #bdb8b8;
          text-decoration: none;
        }
        &:before {
          // margin-right: 10px;
        }
        .submenu_image {
          float: right;
        }
      }
      // .dropdown-submenu a.hasDropDown {
      //   &:after {
      //     content: "▸";
      //     display: inline-block;
      //     position: absolute;
      //     right: 0;
      //   }
      // }
      li {
        position: relative;
        float: left;
        width: 100%;
        &:hover ul {
          top: 0;
          left: 100%;
          width: 100%
        }
      }
    }
    &:last-child .submenu li:hover ul {
      left: -100%;
    }
  }

  @media (max-width: 768px) {
    .plone-navbar-collapse.in {
      .opener {
        background: transparent;
        border: 1px solid white;
        border-radius: (@plone-font-size-base * .25);
        float: right;
        text-align: center;
        width: @plone-font-size-base * 2;
        height: @plone-font-size-base * 2;
        margin-top: -(@plone-font-size-base * 0.4);
        margin-right: -(@plone-font-size-base * 0.7);
        .caret {
          display: inline-block;
          width: 0;
          height: 0;
          margin-left: 0;
          margin-bottom: 0.2em;
          vertical-align: bottom;
          border-top:    0.5em dashed;
          border-right:  0.5em solid transparent;
          border-left:   0.5em solid transparent;
          border-bottom: 0;
        }
      }
      .menu-open { display: block!important; }
      .plone-navbar-nav {
          margin: 0 -15px 0;
      }
      li {
        &:hover > .submenu {
          display: none;
        }
        .submenu {
          .opener {
            background: transparent;
            border: 1px solid white;
            border-radius: (@plone-font-size-base * .25);
            float: right;
            text-align: center;
            width: @plone-font-size-base * 2;
            height: @plone-font-size-base * 2;
            margin-top: -(@plone-font-size-base * 0.4);
            margin-right: -(@plone-font-size-base * 0.7);
            .caret {
              display: inline-block;
              width: 0;
              height: 0;
              margin-left: 0;
              margin-bottom: 0.2em;
              vertical-align: bottom;
              border-top:    0.5em dashed;
              border-right:  0.5em solid transparent;
              border-left:   0.5em solid transparent;
              border-bottom: 0;
            }
          }
          // display: block;
          position: relative;
          a {
            &:hover {
              // background-color: unset;
            }
          }
          li {
            float: none;
            &:hover ul {
                top: unset;
                left: unset;
            }
          }
        }
        .opener.menu-open {
          > .caret {
            border-top:    0;
            border-right:  0.5em solid transparent;
            border-left:   0.5em solid transparent;
            border-bottom: 0.5em dashed;
          }
        }
        .navTreeLevel0 .submenu_title {
          padding-left: 0.5em;
        }
        .navTreeLevel1 .submenu_title {
          padding-left: 1em;
        }
        .navTreeLevel2 .submenu_title {
          padding-left: 1.5em;
        }
        .navTreeLevel3 .submenu_title {
          padding-left: 2em;
        }
      }
      .plone-navbar-nav li {
        .submenu {
          // display: none;
        }
        &:hover > .submenu {
          display: unset;
        }
      }
    }
  }

/* PortletStaticText portlet-static-last-newsletter */

div.portletStaticText.portlet-static-last-newsletter > table > tbody > tr > td {
    background-color: #fafafa;
    border: none;
}

a.read_more {
    float: right;
}

/* portal-slider */

div.bannerLink {
    margin: auto;
    text-align: center;
}

/* footer */

#portal-footer-wrapper a {
    color: #ffffff;
}

table {
    width: 100%;
    max-width: 100%;
    border: 1px solid #fafafa;
    border-color: #fafafa;
    background-color: #fafafa;
}

table > thead > tr > th, table > tbody > tr > th, table > tfoot > tr > th, table > thead > tr > td, table > tbody > tr > td, table > tfoot > tr > td {
    border: 1px solid #fafafa;
}

div.portletStaticText.portlet-static > table > tbody > tr > td.fct {
    padding-top: 5px;
}

div.portletStaticText.portlet-static > table > tbody > tr > td.compete2020 {
    padding-top: 5px;
}

/* Research Team */

.listing > tbody > tr:nth-child(odd) > td, .listing > tbody > tr:nth-child(odd) > th {
    background-color: #e6e3e3;
}

table.state-published td:first-child {
    width: 230px;
}

/* Contacts */

td.contact {
    padding-left: 5px;
}

/* Calendar background color in events */
.vevent span.cal_month {
    font-size: 1.1em;
    line-height: 1.3em;
    background: #8c2c18;
    display: block;
}

/* Portlet little ball */

.portlet .portletContent > ul > li a::before {
    content: "•";
    position: absolute;
    font-size: 25px;
    margin-top: -9px;
    left: 15px;
    color: rgb(140, 44, 24);
}

/* Pagination list */

nav.pagination ul li {
    display: inline;
}

/* Research Groups */

table.group {
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
}

td.group {
    border: 4px solid #ffffff;
    width: 400px;

}

/* Conferences */

table.conferences {
    margin-left: auto;
    margin-right: auto;
    max-width: 500px;
}

table.conferences td:first-child {
    width: 50px;
    padding-top: 5px;
}

table.conferences td {
    padding-top: 10px;
}

/* Newsletters */

table.newsletters {
    margin-left: auto;
    margin-right: auto;
    max-width: 300px;
}

table.newsletters td:first-child {
    width: 250px;
}

/* PhD Programs */

table.phd {
    margin-left: 50px;
    margin-right: auto;
    max-width: 400px;
}

table.phd td:first-child {
    width: 50px;
}

/* MSc Programs */

table.msc {
    margin-left: 50px;
    margin-right: auto;
    max-width: 400px;
}

table.msc td:first-child {
    width: 50px;
}

/* Others Specialized Programs */

table.osp {
    margin-left: 50px;
    margin-right: auto;
    max-width: 400px;
}

table.osp td:first-child {
    width: 100px;
}

/* Search */

.pat-livesearch .livesearch-results {
    display: none;
    position: absolute;
    background-color: #fafafa;
    z-index: 100;
    border-right: 1px solid rgb(144, 44, 24);
    border-bottom: 1px solid #8c2c18;
    border-left: 1px solid #8c2c18;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(140,44,24,.075), 0 0 8px rgba(140,44,24,0.6);
    box-shadow: inset 0 1px 1px rgba(140,44,24, .075), 0 0 8px rgba(140,44,24,0.6);
    -moz-box-shadow: inset 0 1px 1px rgba(140,44,24,.075),0 0 8px rgba(140,44,24,0.6);
    margin: 0;
    padding: 0;
    list-style: none;
}

input[type="text"]:focus, input[type="password"]:focus, textarea:focus, select:focus {
    border-color: #8c2c18;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(140,44,24,.075), 0 0 8px rgba(140, 44, 24,0.6);
}

li.search-result > h4 {
    font-size: 13px;
}

/* News */

.news_published {
    float: right;
    font-style: italic;
    color: #8c2c18;
    font-size: 12px;
}

.portlet {
    margin-top: 10px;
}

/* Hide glyphicons */

i.glyphicon.link-external, i.glyphicon.link-https {
    display: none;
}

/* Groups Portlet */

.groups-portlet {
    font-size: 12px;
    text-align: left;
    line-height: 1.5em;
    margin-top: 0px;
}

#content {
    margin-bottom: 30px;
}

div.portletStaticText.portlet-static-last-newsletter > table > tbody > tr > td {
    background-color: #fafafa;
    border: none;
    padding-left: 60px;
}

/* sponsors */

table.sponsors  {
    height: 60px;
}

div.portletStaticText.portlet-static > table > tbody > tr > td.up {
    padding-top: 16px;
    width: 23%;
}

div.portletStaticText.portlet-static > table > tbody > tr > td.fctuc {
    padding-top: 5px;
    width: 23%;
}

div.portletStaticText.portlet-static > table > tbody > tr > td.fct {
    padding-top: 18px;
    width: 23%;
}

div.portletStaticText.portlet-static > table > tbody > tr > td.compete2020 {
    padding-top: 16px;
    width: 41%;
}

img.image-inline.up {
    width: 85px;
}

img.image-inline.uc {
    height: 50px;
    width: 95px;
}

img.image-inline.compete2020 {
    height: 30px;

}

/* Submenu width */

.plone-navbar-nav li .submenu {
    width: 290px;
    font-size: 13px;
}

/* breadcrumb */

.plone-breadcrumb {
    margin-top: 35px;

}

.plone-breadcrumb ol > li {
    font-weight: 300;
    display: inline-block;
    line-height: initial;
    position: relative;
    padding: 0 30px 0 0;
}

.plone-breadcrumb ol > li + li::before {
    content: "";
    position: absolute;
    padding: 0 5px;
    color: #e3e5e5;
    border-left: 5px solid;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    left: -20px;
    top: -15px;
    padding: 0 !important;
}

/* research group images */

td.groups {
    width: 246px;
    border-top: 10px solid #fafafa;
}

td.groups_middle {
    border: 5px solid #fefefe;
    width: 5px;
}

td.groups_text {
/*    border: 5px solid #ffffff; */
    background-color: #e5e3e3;
    font-size: 12px;
    line-height: 1.3em;
    padding: 2px 10px;
    text-align: left;
}

/* Format portlets */

.portlet.portletNews, .portlet.portletStaticText.portlet-static-advanced-training {
    margin-top: 35px;
}

.portlet.portletEvents {
    margin-top: 10px;
}

.portlet.portletStaticText.portlet-static-last-newsletter {
    margin-top: 10px;
}

/* portal logo */

#portal-header #portal-logo {
    margin-left: -7px;
}

.location {
    text-align: left;
}

div.col-xs-4.col-sm-1.team {
    margin: 0;
    padding: 0;
}

div.col-xs-4.col-sm-4.team {
    padding: 0;
}

div.col-sm-4.contributions {
    padding: 6px;
}

div.col-xs-4.contributions {
    padding: 2px;
}

.image-right {
    margin: 0;
    margin-left: 30px;
    margin-top: 20px;
}

